$(window).on('scroll', function () {
  var $sections = $('.portfolio-stage__item');
  $sections.each(function (i, el) {
    var top = $(el).offset().top - 200;
    var bottom = top + $(el).height();
    var scroll = $(window).scrollTop();
    var id = $(el).attr('id');
    if (scroll > top && scroll < bottom) {
      console.log(4546);
      $('.portfolio-stage__nav a.active').removeClass('active');
      $('.portfolio-stage__nav a[href="#' + id + '"]').addClass('active');
    }
  });
});
$('body').on('click', '.portfolio-stage__nav a', function (event) {
  event.preventDefault();
  var id = $(this).attr('href'),
    top = $(id).offset().top - 80;
  $('body,html').animate({ scrollTop: top }, 500);
});
