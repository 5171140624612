let store = {};
window.store = store;

store.tabs = function () {
  let tabsWrapper = $('.tab-wrap');

  tabsWrapper.on('click', '.tab-nav .item', switchTab);

  function switchTab(event) {
    var curentTab = $(this),
      tabWrapper = $(event.delegateTarget),
      visibleContent = $('.' + curentTab.attr('rel'));

    $('.active', tabWrapper).removeClass('active');
    curentTab.addClass('active');

    $('.visible-content', tabWrapper).removeClass('visible-content');
    visibleContent.addClass('visible-content');
  }
};

if ($('.tab-wrap')[0]) {
  store.tabs();
}
