let checkBoxes = document.querySelectorAll('[data-cost]');
let checkboxArr = [];
let rangeSliderArr = [];
const calculate = document.getElementById('calculate');
let result = document.getElementById('result-price');
let modalResultInput = document.getElementById('modal-result-value');


function calcSum(){
  let checkBoxesSum = Number(calculate.getAttribute('data-check-boxes'));
  let slidesSum = Number(calculate.getAttribute('data-range-slides'));
  let sum = checkBoxesSum + slidesSum;
  result.textContent = sum.toString();
  modalResultInput.value = sum;
}

checkBoxes.forEach((checkBox, index) => {
  checkBox.addEventListener('change', (item) => {
    let itemId = checkBox.getAttribute('id');
    let itemCost = checkBox.getAttribute('data-cost');
    if (checkBox.checked) {
      checkboxArr.push({id: itemId, cost: Number(itemCost)});
    } else {
      let currentItem = checkBox.getAttribute('id');
      checkboxArr = checkboxArr.filter(comparedItem => comparedItem.id !== currentItem);
    }
    let numbersArr = checkboxArr.map(item => item.cost);
    calculate.dataset.checkBoxes = numbersArr.reduce((a, b) => a + b, 0);
    calcSum()
  })
})


$(".js-range-slider").ionRangeSlider({
  skin: "round",
  decorate_both: false,
  hide_min_max: true,
  grid: true,
  onStart: function (data) {
    let sliderTargetId = data.input[0].getAttribute('data-id');
    let targetId = document.getElementById(sliderTargetId);
    rangeSliderArr.push({id: sliderTargetId, sliderSum: targetId.getAttribute('data-range-cost')});
  },
  onChange: function (data) {
    let step = data.from
    let stepCost = Number(data.input[0].getAttribute('data-step-cost'));
    let currentSlidePrice = data.input[0].closest('.box-item');
    let stepCalc = step * stepCost;
    currentSlidePrice.dataset.rangeCost = `${stepCalc}`;
    let stepsSum = document.querySelectorAll('[data-range-cost]');

    stepsSum.forEach((item, index) => {
      let itemId = item.getAttribute('id');
      let itemPrice = item.getAttribute('data-range-cost');
      if (rangeSliderArr[index].id === itemId) {
        rangeSliderArr[index].sliderSum = Number(itemPrice);
      }
    })
    let sliderNumbers = rangeSliderArr.map(item => item.sliderSum);
    calculate.dataset.rangeSlides = sliderNumbers.reduce((a, b) => a + b, 0);
    calcSum()
  },
});


