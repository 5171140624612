/*-------------------- MODAL WINDOW	--------------------*/
$('.popup-open').on('click', function (e) {
  $('body').addClass('no-scroll');
  $('.popup, .nav-menu, .nav-btn, .overlay-menu').removeClass('active');
  var rel = $(this).attr('rel');
  $('.popup-' + rel).addClass('active');
  e.preventDefault();
});
$('.popup-close, .overlay, .popup-overlay, .btn-close').on('click', function () {
  $('body').removeClass('no-scroll');
  $('.popup').removeClass('active');
});

// Получаем все элементы с классом "customInput"
var inputs = document.querySelectorAll('input[type="tel"]');

// Добавляем обработчик события input для каждого элемента
inputs.forEach(function (input) {
  input.addEventListener('input', function () {
    updateInput(input);
  });
});

function updateInput(input) {
  // Получаем значение из текущего поля ввода
  var inputValue = input.value;

  // Проверяем, начинается ли строка с символа "+"
  if (!inputValue.startsWith('+')) {
    // Если нет, то добавляем его в начало строки
    input.value = '+' + inputValue;
  }
}
