if ($('#quiz-form')[0]) {
  $('.quiz__item .btn-sm').on('click', function () {
    const item = $(this).attr('rel');
    $('.quiz__item').removeClass('active');
    $('.' + item).addClass('active');
    var arr = $(this)
      .parents('.quiz__item')
      .find('input:checked')
      .map(function () {
        return this.value;
      });
    console.log(arr);
  });
}
