// SLIDER TESTIMONIALS
if ($('.s-testimonials__slider')[0]) {
  $('.s-testimonials__slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    speed: 800,
    cssEase: 'ease-out',
    touchThreshold: 20,
    adaptiveHeight: true,
    fade: true,
    appendArrows: '.s-testimonials__slider-arrow',
    nextArrow: `<span class="slick-arrow-next">
      <svg width="20" height="20" viewBox="0 0 20 20">
        <path d="M7.15833 13.825L10.975 10L7.15833 6.175L8.33332 5L13.3333 10L8.33332 15L7.15833 13.825Z"/>
      </svg>
    </span>`,
    prevArrow: `<span class="slick-arrow-prev">
      <svg width="20" height="20" viewBox="0 0 20 20">
        <path d="M12.8417 13.825L9.025 10L12.8417 6.175L11.6667 5L6.66667 10L11.6667 15L12.8417 13.825Z"/>
      </svg>
    </span>`,
  });
}
