if (document.getElementById('js-sticker')) {
  const sticker = document.getElementById('js-sticker');
  const triggerParent = sticker.closest('div.sticker-wrap');
  const checkData = sessionStorage.getItem('sticker');

  if (checkData === 'collapsed' && checkData) {
    triggerParent.classList.remove('active');
  } else {
    triggerParent.classList.add('active');
  }

  sticker.addEventListener('click', function () {
    triggerParent.classList.toggle('active');
    if (triggerParent.classList.contains('active')) {
      sessionStorage.removeItem('sticker');
    } else {
      sessionStorage.setItem('sticker', 'collapsed');
    }
  });
}
