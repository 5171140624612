/* ----------------------- MENU ---------------------- */
$('body').on('click', '.nav-btn', function (event) {
  $(event.currentTarget).toggleClass('active');
  $('.nav-menu, .overlay-menu').toggleClass('active');
  $('body').toggleClass('no-scroll');
  return false;
});

$(window)
  .on('resize.myTemplate', function () {
    $('body')[$(this).width() < 1200 ? 'addClass' : 'removeClass']('isMobile');
  })
  .trigger('resize.myTemplate');

$('body').on('click', '.dropdown>span', function (event) {
  if (!$('body.isMobile')[0]) {
    return;
  }

  var $thisLi = $(event.currentTarget).parents('li'),
    $thisLiActive = $thisLi.hasClass('dropdown-active');

  $('.dropdown-active').removeClass('dropdown-active').children('.dropdown-list').slideUp('fast');

  if (!$thisLiActive) {
    $thisLi.addClass('dropdown-active');
    $thisLi.children('.dropdown-list').slideDown('fast');
  }

  return false;
});

$('body')
  .on('mouseenter', '.dropdown', function (event) {
    if ($('body.isMobile')[0]) {
      return;
    }

    var menuItem = $(event.currentTarget);

    if (menuItem[0].timeOutMenu) {
      clearTimeout(menuItem[0].timeOutMenu);
    }

    menuItem.addClass('active');
  })
  .on('mouseleave', '.dropdown', function (event) {
    if ($('body.isMobile')[0]) {
      return;
    }

    var menuItem = $(event.currentTarget);

    menuItem[0].timeOutMenu = setTimeout(function () {
      menuItem.removeClass('active');
    }, 0);
  });

$('.overlay-menu').on('click', function () {
  $(this).removeClass('active');
  $('.nav-menu, .nav-btn').removeClass('active');
  $('body').removeClass('no-scroll');
});

//Custom select
if ($('.custom-select')[0]) {
  $('.custom-select').on('click', function () {
    $(this).toggleClass('active');
  });

  $('.custom-select ul a').on('click', function () {
    const attrSelect = $(this).text();
    $(this).parents('.custom-select').removeClass('active');
    $(this).parents('.custom-select').find('.current').text(attrSelect);
  });

  $(document).on('mouseup', function (e) {
    var currentSelect = $('.custom-select');
    if (!currentSelect.is(e.target) && currentSelect.has(e.target).length === 0) {
      currentSelect.removeClass('active');
    }
  });
}

/*-------------------- FANCYBOX	--------------------*/
if ($('[data-fancybox]')[0]) {
  $('[data-fancybox]').fancybox({
    animationEffect: 'zoom',
    transitionEffect: 'tube',
    buttons: ['zoom', 'close'],
  });
}
