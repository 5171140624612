'use strict';

document.addEventListener('DOMContentLoaded', function () {
  import('./components/menu');
  import('./components/header-sticky');
  import('./components/modal');
  import('./components/tabs');
  import('./components/sliders');
  import('./components/counters');
  import('./components/quiz');
  import('./components/animate-scroll');
  import('./components/sticker');
  import('./components/pricing'); 
});
