$(document).ready(function () {
  if ($('#counters').length) {
    $(window).scroll(startCounter);
    function startCounter() {
      var hT = $('#counters').offset().top,
        hH = $('.js-counter').outerHeight(),
        wH = $(window).height();
      if ($(window).scrollTop() > hT + hH - wH) {
        $(window).off('scroll', startCounter);
        $('.js-counter').each(function () {
          var $this = $(this);
          $({ Counter: 0 }).animate(
            { Counter: $this.attr('data-count') },
            {
              duration: 2000,
              easing: 'swing',
              step: function () {
                $this.text(Math.ceil(this.Counter));
              },
            }
          );
        });
      }
    }
  }
});
